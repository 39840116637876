import { useAuthContext } from '@/contexts/AuthContext';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { trackFeedCardView } from '@/services/feedImpressionService';
import config from '@/utility/config';
import { addMinutes, format } from 'date-fns';
import { useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import Cookies from 'js-cookie';
import useSessionId, {
  VIEWING_SESSION_ID_KEY
} from '@/hooks/useSessionId';

function useFeedAnalytics({ postId, canUserViewPost }) {
  const { user } = useAuthContext();
  useSessionId();

  // If they view 75% of the post, it will be considered an impression count
  const [feedCardRef, { entry }] = useIntersectionObserver({
    threshold: 0.75
  });

  const trackImpressionOnPost = () => {
    const now = new Date();
    const utcOffset = now.getTimezoneOffset();
    const utcDate = addMinutes(now, utcOffset);
    const currentDateTimeUTC = format(utcDate, 'yyyy-MM-dd HH:mm:ss');
    const sessionId = Cookies.get(VIEWING_SESSION_ID_KEY);

    const payload = {
      entitytype: 'post',
      sessionid: user?.email ?? sessionId,
      entityid: postId,
      m_time: currentDateTimeUTC,
      user_id: user?._id ?? sessionId, // if not logged in, just use session id
      env: config.isDevEnvironment ? 'dev' : 'prod'
    };
    trackFeedCardView(payload);
  };

  // Debounce the tracking function
  // So if user scrolls through the feed fast, it doesnt record every
  // card the user scrolls past
  const debouncedTrackImpression = useCallback(
    debounce(() => {
      if (canUserViewPost) {
        trackImpressionOnPost();
      }
    }, 1000), // Adjust the delay (in ms) as needed
    [canUserViewPost, trackImpressionOnPost]
  );

  useEffect(() => {
    // If isIntersecting = can view 75% of the card, then track impression
    if (entry?.isIntersecting) {
      debouncedTrackImpression();
    } else {
      // cancel if cannot view/scroll past the card in less than 1 second
      debouncedTrackImpression.cancel();
    }

    // Cleanup function to cancel the debounce if the component unmounts
    return () => {
      debouncedTrackImpression.cancel();
    };
  }, [entry, debouncedTrackImpression]);

  return { feedCardRef, trackImpressionOnPost };
}

export default useFeedAnalytics;
