import LinkAwareText from '@/components/common/LinkAwareText';
import NPLButton from '@/components/npl/NPLButton';
import { trackGAEvent } from '@/utility/analytics';
import { t } from '@/utility/localization';
import React, { useEffect, useRef, useState } from 'react';

const AboutCommunity = ({ communityPublicPageData }) => {
  const { code, description } = communityPublicPageData || {};
  const [showMore, setShowMore] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const previewHeight = 168;
  const seeMoreRef = useRef(null);

  const toggleSeeMore = () => {
    trackGAEvent('toogle_see_more_about_community_clicked', {
      communityCode: code
    });
    setShowMore((prev) => !prev);
  };

  useEffect(() => {
    if (seeMoreRef) {
      const { offsetHeight } = seeMoreRef.current;
      setShowSeeMore(offsetHeight > previewHeight);
    }
  }, [showMore]);

  return (
    <div className="c-AboutCommunity">
      <div className="mb-16 text-heading-md font-semibold">
        {t('about')}
      </div>
      <div className="mt-16">
        <div className="text-body-md text-npl-text-icon-on-light-surface-primary">
          <div
            className="relative h-full overflow-hidden transition-[max-height] duration-500 ease-in-out"
            style={{ maxHeight: showMore ? '5000px' : previewHeight }}>
            <div ref={seeMoreRef} className=" whitespace-pre-line">
              <LinkAwareText text={description} />
            </div>
          </div>
          {showSeeMore && (
            <div className="mt-8 flex w-full justify-center">
              <NPLButton
                key={showMore ? 'more' : 'less'}
                hierarchy="plain"
                buttonText={showMore ? t('read-less') : t('read-more')}
                onClick={toggleSeeMore}
                tailIcon={showMore ? 'chevron-up' : 'chevron-down'}
                stretch
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutCommunity;
