import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { ENTITY_SLUGS_PREFIX } from '@/utility/helpers';
import { t } from '@/utility/localization';
import {
  MEMBER_PORTAL_DEFAULT_PAGE_ROUTE,
  getEntityPublicPageUrl
} from '@/utility/routesHelper';
export const VISIBILITY_TYPE_PUBLIC = 'public';
export const VISIBILITY_TYPE_MEMBERS = 'members';

export const getPublicOption = () => ({
  id: VISIBILITY_TYPE_PUBLIC,
  label: t('public'),
  description: t('anyone-can-see-your-posts'),
  icon: 'globe-02'
});

export const getMemberOption = () => ({
  id: VISIBILITY_TYPE_MEMBERS,
  label: t('members'),
  description: t('only-members-of-your-community-can-see-your-posts'),
  icon: 'user-circle'
});

export const getPostAccessIcon = (post) => {
  try {
    const { visibilityType } = post;
    const isPublic = visibilityType === VISIBILITY_TYPE_PUBLIC;
    return isPublic ? getPublicOption().icon : getMemberOption().icon;
  } catch (e) {
    return VISIBILITY_TYPE_MEMBERS;
  }
};

export const getPostShareUrl = (post, community) => {
  try {
    const { slug } = post;
    const { link: communityLink } = community;
    const communityLinkWithoutSlash = communityLink.replace(/\//g, '');
    const slugWithoutSlash = slug.replace(/\//g, '');
    return getEntityPublicPageUrl({
      communitySlug: communityLinkWithoutSlash,
      entityType: ENTITY_SLUGS_PREFIX.POSTS,
      entitySlug: slugWithoutSlash,
      fullUrl: true
    });
  } catch (e) {
    return '';
  }
};

export const getPostShareUrlWithCommunityLink = (
  post,
  communityLink,
  isRelativeLink
) => {
  try {
    const { slug } = post;
    const communityLinkWithoutSlash = communityLink.replace(/\//g, '');
    const slugWithoutSlash = slug.replace(/\//g, '');

    if (isRelativeLink) {
      return getEntityPublicPageUrl({
        communitySlug: communityLinkWithoutSlash,
        entityType: ENTITY_SLUGS_PREFIX.POSTS,
        entitySlug: slugWithoutSlash,
        fullUrl: false
      });
    }

    return getEntityPublicPageUrl({
      communitySlug: communityLinkWithoutSlash,
      entityType: ENTITY_SLUGS_PREFIX.POSTS,
      entitySlug: slugWithoutSlash,
      fullUrl: true
    });
  } catch (e) {
    return '';
  }
};

export const LOCKED_POST_BG_IMG_SRC = `${STATIC_ASSET_BASE_URL}/nasio/post/locked-post-blur-bg.png`;

export const FEED_EDU_MODAL_IMAGE = `${STATIC_ASSET_BASE_URL}/nasio/post/feed-edu-modal.jpg`;

export const getMpSinglePostLink = (postId, communityId, extras = {}) => {
  try {
    if (!postId || !communityId) return '';

    const urlParams = new URLSearchParams({
      ...extras,
      activeCommunityId: communityId
    });
    return `${MEMBER_PORTAL_DEFAULT_PAGE_ROUTE}/post/${postId}?${urlParams.toString()}`;
  } catch (e) {
    return '';
  }
};

export const HOMEPAGE_FEED_EDUCATION_VIEWED_KEY =
  'homepage-post-education-modal-viewed';
export const FEED_PAGE_POST_VISIBILITY_EDUCATION_VIEWED_KEY =
  'feedpage-post-visibility-education-popup-viewed';
export const MAGIC_REACH_PAGE_POST_VISIBILITY_EDUCATION_VIEWED_KEY =
  'MRpage-post-visibility-education-popup-viewed';

// Education modals should only show for CMs who signed up before this date
export const FEED_FEATURE_RELEASE_DATE = '2023-08-31T00:00:00Z';

export const checkDidCmSignUpBeforeFeedsReleaseDate = (user) => {
  try {
    const { createdAt } = user;
    const userCreatedAt = new Date(createdAt);
    const feedFeatureReleaseDate = new Date(FEED_FEATURE_RELEASE_DATE);
    return userCreatedAt < feedFeatureReleaseDate;
  } catch (e) {
    return false;
  }
};
