import React from 'react';
import { getPostAccessIcon } from '../../utils';
import Icon from 'icons';
import NextImage from '@/components/common/NextImage';
import { getTimeAgoString } from '@/utility/dateHelper';

const className = 'c-PostAuthorData';

const getProfileImageFormatted = (url) => {
  return {
    alt: 'profile image',
    mobileImgProps: {
      src: url,
      width: 40,
      height: 40,
      quality: 50,
      objectFit: 'cover'
    }
  };
};

const PostAuthorData = ({
  post = null,
  showPostVisibilityType = true
}) => {
  if (!post) return null;

  const postAccessIcon = getPostAccessIcon(post);
  const { author } = post;
  return (
    <div className={`${className} flex items-center space-x-12`}>
      {/* Author Image */}
      <div className="h-40 w-40 overflow-hidden rounded-full">
        <NextImage
          {...getProfileImageFormatted(author?.profileImage)}
          className="h-40 w-40 rounded-full"
        />
      </div>
      <div className="space-y-4">
        {/* Author Name */}
        <p className="text-label-md text-dark-1b">
          {author?.firstName ?? ''} {author?.lastName ?? ''}
        </p>
        {/* Post visibility & Time Ago String */}
        <div className="flex items-center space-x-4 text-[#1B1B1880]">
          {/* Created At */}
          <p className="text-label-md text-dark-1b text-opacity-65">
            {getTimeAgoString(post?.createdAt)}
          </p>
          {showPostVisibilityType ? (
            <>
              <div>·</div>
              <Icon name={postAccessIcon} fill="#1B1B1880" width={16} />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PostAuthorData;
