import CommunityPageLayout from '@/components/features/CommunityPage/layouts/CommunityPageLayout';
// import { fetchCommunityDataBySlug } from '@/services/communitiesService';
import React from 'react';
import HomeTab from '../../components/features/CommunityPage/tabContents/HomeTab';
import { getCommunityStaticProps } from './utils';
import useTrackTabPageView from '@/components/features/CommunityPage/hooks/useTrackTabPageView';
import { TAB_PAGE_VIEW_EVENT_NAME } from '@/components/features/CommunityPage/constants';
import PageMetaTags from '@/components/common/PageMetaTags';
import { checkIfIndexable } from '@/utility/helpers';
import config from '@/utility/config';
import AuthNavbarPageLayout from '@/components/features/Navbar/AuthNavbarPageLayout';

const CommunityPage = ({
  communityPublicPageData,
  discountPercentageForHighestInterval,
  hasScrolledPastBanner,
  userCommunitiesData,
  genericTrackingFields,
  mutateCommunityData
}) => {
  useTrackTabPageView({
    eventName: TAB_PAGE_VIEW_EVENT_NAME.HOME,
    communityId: communityPublicPageData?._id
  });

  const communityLink = communityPublicPageData?.slug;

  return (
    <div>
      {/* Meta tags for SEO */}
      <PageMetaTags
        {...communityPublicPageData?.metadata}
        canonicalUrl={`${config.siteBaseUrl}${communityLink}`}
        description={communityPublicPageData?.description}
        indexable={checkIfIndexable(communityPublicPageData)}
      />
      <HomeTab
        communityPublicPageData={communityPublicPageData}
        discountPercentageForHighestInterval={
          discountPercentageForHighestInterval
        }
        hasScrolledPastBanner={hasScrolledPastBanner}
        genericTrackingFields={genericTrackingFields}
        userCommunitiesData={userCommunitiesData}
        mutateCommunityData={mutateCommunityData}
      />
    </div>
  );
};

CommunityPage.getLayout = function getLayout(page) {
  const communityPublicPageData = page.props.communityPublicPageData;
  return (
    <AuthNavbarPageLayout
      communityInfo={communityPublicPageData}
      isCommunityPageView={true}>
      {(userCommunitiesData) => (
        <CommunityPageLayout
          communityPublicPageData={communityPublicPageData}
          showBanner
          userCommunitiesData={userCommunitiesData}>
          {page}
        </CommunityPageLayout>
      )}
    </AuthNavbarPageLayout>
  );
};

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking'
  };
}

export const getStaticProps = getCommunityStaticProps;
export default CommunityPage;
