// BlurImageWithLock.tsx
import React from 'react';
import withComponentClassName from '@/utility/withComponentClassName';
import NPLButton from '@/components/npl/NPLButton';
import { t } from '@/utility/localization';

type Props = {
  imageUrl: string;
  onClick: () => void;
};

const BlurImageWithLock = ({ imageUrl, onClick }: Props) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <div className="aspect-h-1 aspect-w-2 relative">
      <div className="absolute inset-0 z-10 flex items-center justify-center">
        <NPLButton
          leadIcon="lock-01"
          hierarchy="neutral_primary"
          buttonText={t('join-to-unlock')}
          darkMode
          size="md"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        />
      </div>
      <div className=" absolute inset-0 z-1 rounded-12 bg-npl-transparent-black-25"></div>
      <img
        alt="post cover"
        className="rounded-12 object-cover"
        src={imageUrl}
      />
    </div>
  );
};

export default withComponentClassName(BlurImageWithLock);
