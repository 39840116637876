import React from 'react';
import { iconMap } from './iconMap';

/*
Usage:
  <Icon name="arrow"/>
*/

/**
 * @param {String} name
 * @param {Number} width
 * @param {Number} height
 * @param {String} fillColor
 */
const Icon = ({ name = '', ...rest }) => {
  const SelectedIcon = iconMap[name];

  if (!SelectedIcon) {
    console.error(`Invalid icon name passed! passed: "${name}"`);
    return null;
  }

  return <SelectedIcon {...rest} />;
};

export default Icon;
