import { t } from '@/utility/localization';
import React, { useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import ChevronRight from '@/icons/collection/ChevronRight';
import Editor, { VariableTypes } from '@/components/common//Editor/Editor';
import Link from 'next/link';
import { useAuthContext } from '@/contexts/AuthContext';

const MIN_PARAGRAPHS = 1;
const MAX_PARAGRAPHS = 4;
const MAX_CHARACTERS = 250;
const MAX_IMAGES = 1;
const MAX_BUTTONS = 1;

const walkThroughNode = (node, callback) => {
  callback(node);
  if (node.children) {
    node.children.forEach((child) => {
      walkThroughNode(child, callback);
    });
  }
};

export function truncateLexicalContent(root) {
  let paragraphs = 0;
  let characters = 0;
  let images = 0;
  let buttons = 0;

  const truncatedChildren = root.children.filter((child) => {
    if (paragraphs >= MAX_PARAGRAPHS) {
      return false;
    }
    if (characters >= MAX_CHARACTERS) {
      return false;
    }
    if (images >= MAX_IMAGES) {
      return false;
    }
    if (buttons >= MAX_BUTTONS) {
      return false;
    }
    if (child.type === 'paragraph') {
      paragraphs++;
      for (let i = 0; i < child.children.length; i++) {
        const paragraphChild = child.children[i];
        characters += paragraphChild?.text?.length;
      }
      walkThroughNode(child, (node) => {
        if (node.type === 'image') {
          images++;
        }
        if (node.type === 'button') {
          buttons++;
        }
        if (node.type === 'text') {
          characters += node?.text?.length;
        }
      });
    }
    if (child.type === 'image') {
      images++;
    }
    if (child.type === 'button') {
      buttons++;
    }
    const res =
      paragraphs <= MAX_PARAGRAPHS &&
      buttons <= MAX_BUTTONS &&
      images <= MAX_IMAGES &&
      (characters <= MAX_CHARACTERS || paragraphs <= MIN_PARAGRAPHS);

    return res;
  });

  return {
    ...root,
    children: truncatedChildren,
    meta: {
      truncated: truncatedChildren.length < root.children.length
    }
  };
}

const PostContent = ({
  // postId,
  postAuthor,
  content = null,
  withShowMoreOption = false,
  link,
  community
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleSeeMoreClick = () => {
    setExpanded(true);
  };

  const { user } = useAuthContext();

  const { truncatedContent, isTruncated } = useMemo(() => {
    let truncatedContentRes = null;

    if (withShowMoreOption && content) {
      truncatedContentRes = truncateLexicalContent(
        cloneDeep(content?.root)
      );
    }

    const isTruncated = !!truncatedContentRes?.meta?.truncated;
    const truncatedContent = { root: truncatedContentRes };
    return { truncatedContent, isTruncated };
  }, [content, withShowMoreOption]);

  const editorVariables = [
    {
      text: community?.title,
      variable: VariableTypes.CommunityName
    },
    {
      text: user?.learner
        ? `${user.learner.firstName || ''} ${user.learner.lastName || ''}`
        : '',
      variable: VariableTypes.MemberName
    },
    {
      text: `${postAuthor?.firstName} ${postAuthor?.lastName}`,
      variable: VariableTypes.CommunityManagerName
    }
  ];

  return (
    <div className="c-PostContent">
      {withShowMoreOption && !expanded && isTruncated ? (
        // Truncated Content
        <div className="truncated">
          <div className="relative max-h-[250px] overflow-hidden">
            <Editor
              existingContent={truncatedContent}
              editable={false}
              customContentClasses="w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
              variables={editorVariables}
            />
            <div className="bg-gradient-to-b absolute left-0 top-0 h-full w-full from-transparent to-white-default group-hover:to-npl-neutral-light-solid-2"></div>
          </div>
          <div className="mt-14 flex justify-center">
            {link ? (
              <Link
                className="flex items-center justify-center space-x-6 text-button-md font-medium text-dark-1b"
                href={link}
                rel="noreferrer">
                <p>{t('see-more')}</p>
                <ChevronRight />
              </Link>
            ) : (
              <button
                className="flex items-center justify-center space-x-6 text-button-md font-medium text-dark-1b"
                onClick={handleSeeMoreClick}>
                <p>{t('see-more')}</p>
                <ChevronRight />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="full">
          {/* Full content */}
          <Editor
            existingContent={content}
            editable={false}
            customContentClasses="w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
            variables={editorVariables}
          />
        </div>
      )}
    </div>
  );
};

export default PostContent;
